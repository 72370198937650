.App {
    text-align: center;
}

/* 
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
} */

.crossed {
    background-image: linear-gradient(to bottom right, transparent calc(50% - 1px), gray, transparent calc(50% + 1px)),
        linear-gradient(to top right, transparent calc(50% - 1px), gray, transparent calc(50% + 1px));
    ;
    font-style: italic;
}


/* Rotation Group */
.rotation-result ul {
    padding: 0;
    font-size: 0;
    overflow: hidden;
    display: inline-block;
    border-radius: 10px;
    border: 2px solid rgb(102, 102, 102);
}

.rotation-result li {
    display: inline-block;
}

.rotation-result span {
    font-size: 1rem;
    position: relative;
    display: inline-block;
    background: #eee;
    text-decoration: none;
    color: #555;
    padding: 13px 25px 13px 10px;
}

.rotation-result li:first-child span {

    background: #6b7280;
    color: #e5e7eb;
    font-weight: bold;
}

.no-after:after,
.no-after:after {
    content: none;
    display: none !important;
}

/* .no-after {
    content: none;
} */


.rotation-result span.station-of-group:after,
.rotation-result span.station-of-group:before {
    position: absolute;
    content: "";
    height: 0;
    width: 1px;
    top: 50%;
    left: -25px;
    margin-top: -24px;
    border: 24px solid #eee;
    border-right: 0 !important;
    border-left-color: transparent !important;
}

.rotation-result span.station-of-group:before {
    left: -26px;
    border: 24px solid #555;
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

/* ACTIVE STYLES */
/* .rotation-result span.active {
    background: #ace;
}

.rotation-result span.active:after {
    border-color: #ace;
}

/* HOVER STYLES */
/* 
.rotation-result span:hover {
    background: #cef;
}

.rotation-result span:hover:after {
    border-color: #cef;
}

*/